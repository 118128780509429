<!--Commodity pools-->
<template>
  <div class="panel demoWrapper">
    <div class="serch_box">
      <el-form :inline="true" class="user-search fixed_form">
        <el-form-item label="Product Pool No:" size="mini">
          <template>
            <el-input
              v-model.trim="form.poolNo"
              placeholder="Commodity pool number. Only digits can be entered"
              size=""
            />
          </template>
        </el-form-item>
        <el-form-item label="Product Pool Name:" size="mini">
          <template>
            <el-input
              v-model.trim="form.PoolName"
              size=""
              placeholder="Commodity pool name. You can enter digits, letters, Spaces, and common special characters"
            />
          </template>
        </el-form-item>
        <el-form-item label="Creator:" size="mini">
          <template>
            <el-input
              v-model.trim="form.Creator"
              size=""
              placeholder="Creator, support for input letters and numbers"
            />
          </template>
        </el-form-item>
        <el-form-item label="Modifier:" size="mini">
          <template>
            <el-input
              v-model.trim="form.Modifier"
              size=""
              placeholder="Modifiers, support for input letters and numbers"
            />
          </template>
        </el-form-item>

        <el-form-item label="SKU ID:" size="mini">
          <template>
            <el-input
              v-model.trim="form.skuID"
              type="textarea"
              size="mini"
              placeholder="Item SKU ids. Only digits can be entered. Multiple SKU ids can be queried at the same time  "
            />
          </template>
        </el-form-item>
        <el-form-item label="Create Time:" size="mini">
          <template>
            <el-date-picker
              v-model.trim="createValue"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </template>
        </el-form-item>
        <el-form-item label="Modify Time:" size="mini">
          <template>
            <el-date-picker
              v-model.trim="modifyValue"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </template>
        </el-form-item>
        <el-form-item label="Contact NO:" size="mini">
          <template>
            <el-input
              v-model.trim="form.ContactNO"
              type="textarea"
              placeholder="please enter Contact NO"
              size=""
            />
          </template>
        </el-form-item>

        <el-form-item label="Product Pool Type" size="mini">
          <template>
            <el-select v-model.trim="value" size="mini" placeholder="Please select">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" icon="el-icon-search" @click="searchBtn">
          Search
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="addBtn">
          Mount
        </el-button>
        <el-button type="" @click="offBtn">Reset</el-button>
      </div>
    </div>
    <div class="panel-body">
      <el-table
        ref="multipleTable"
        :data="tableList"
        border
        fit
        style="width: 100%"
        tooltip-effect="dark"
        :header-cell-style="{ background: '#e8f1fe' }"
        @selection-change="handleSelectionChange"
      >
        <!-- 修改无数据时 提示文字 -->
        <template slot="empty" class="logo_no">
          <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
          <p style="color: #969799;">No more data</p>
        </template>
        <el-table-column type="selection" width="55" fixed="left" />
        <el-table-column
          label="Product Pool No"
          width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.Product }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="Product Pool Name"
          width="300"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.bvi_people_name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Remarks"
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.vi_people }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Product Pool Type"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.vi_type }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Contract No"
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.health }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Enterprise ID"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.health }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Creator" width="" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{ scope.row.health }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Modifler"
          width=""
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.health }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Create Time"
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.health }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Modify Time"
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.health }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Operate111" min-width="300" fixed="right">
          <template slot-scope="scope">
            <el-button
              
              type="primary"
              size="small"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
            >
              modification
            </el-button>
            <el-button
              
              type="danger"
              size="small"
              @click="handleDel(scope.row)"
            >
              delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageWrapper">
        <div>
          <el-button type="primary" @click="batchAdd">
            Batch Add Sku
          </el-button>
          <el-button type="primary" @click="batchAdd">
            Batch Add Sku
          </el-button>
        </div>

        <el-pagination
          :current-page="listQuery.pageCode"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="listQuery.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!-- 侧拉抽屉 -->
    <el-drawer
      title="Product Pool Add Category"
      :visible.sync="leftLogin"
      :direction="direction"
      style="margin-top:10vh;margin-right:5%"
      size="40%"
    >
      <p class="box_p">
        if you choose too many categories,the submission process will be very
        slow.please note.
      </p>
      <div style="height:500px;" class="scrollbar">
        <el-form
          ref="postForm"
          :model="postForm"
          :rules="rules"
          label-width="180px"
        >
          <el-form-item label="User Name" prop="UserName">
            <el-input
              v-model.trim="postForm.UserName"
              type="textarea"
              placeholder="Please enter the User Name"
              maxlength="50"
              :rows="5"
              disabled
            />
          </el-form-item>
          <el-form-item label="Category" prop="tree">
            <el-tree
              :props="props"
              :load="loadNode"
              lazy
              show-checkbox
              @check-change="handleCheckChange"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormPassword = false">Cancel</el-button>
          <el-button
            
            type="primary"
            @click="updateDataP('passForm')"
          >
            Submit
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
// 引入API接口
// import { getFirstList } from '@/api/commodityPools/index';
const defaultForm = {
  Operator: '',
  ContractNo: '',
  ProductPoolSKU: '',
  ProdcutPoolName: ''
};
export default {
  // 引入局部刷新
  inject: ['reload'],
  data() {
    return {
      emptyImgSrc: '',
      postForm: Object.assign({}, defaultForm),
      activeName: 'first',
      // tree信息
      props: {
        label: 'name',
        children: 'zones'
      },
      count: 1,
      // 配置分页信息
      listQuery: {
        pageSize: 10,
        pageCode: 1,
        type: 1
      },
      // 总数据条数
      total: 0,
      // 配置弹窗信息
      textMap: {
        update: 'update',
        create: 'create'
      },
      dialogFormVisible: false,
      leftLogin: false,
      dialogStatus: 'update',
      // 搜索条件
      form: {
        poolNo: '',
        PoolName: '',
        Creator: '',
        Modifier: '',
        skuID: '',
        CreatTime: '',
        CreateTime: '',
        ModifyTime: '',
        ContactNO: ''
      },
      // 后端获取表格信息
      tableList: [
        {
          id: 0,
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
          Product: 'ProductO'
        }
      ],
      // 选中列表信息项
      multipleSelection: []
    };
  },
  watch: {
    dialogFormVisible: function() {
      this.$refs['postForm'].resetFields();
    }
  },
  created() {
    // 初始化表数据
    this.getFirstData();
    this.emptyImgSrc = '';
  },
  methods: {
    handleCheckChange(data, checked, indeterminate) {
      //console.log(data, checked, indeterminate);
    },
    handleNodeClick(data) {
      //console.log(data);
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([{ name: 'region1' }, { name: 'region2' }]);
      }
      if (node.level > 3) return resolve([]);

      var hasChild;
      if (node.data.name === 'region1') {
        hasChild = true;
      } else if (node.data.name === 'region2') {
        hasChild = false;
      } else {
        hasChild = Math.random() > 0.5;
      }

      setTimeout(() => {
        var data;
        if (hasChild) {
          data = [
            {
              name: 'zone' + this.count++
            },
            {
              name: 'zone' + this.count++
            }
          ];
        } else {
          data = [];
        }

        resolve(data);
      }, 500);
    },
    batchAdd() {
      this.leftLogin = true;
    },
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    // 获取页面数据
    getFirstData() {
      //console.log('首次加载');
    },
    // 新增
    addBtn() {
      // this.dialogStatus = 'create';
      // this.dialogFormVisible = true;
      this.$router.push({ name: 'Commodity pools' });
    },
    // 分页信息-设置条数
    handleSizeChange(val) {
      window.console.log(`每页 ${val} 条`);
    },
    // 分页信息-切换页数
    handleCurrentChange(val) {
      window.console.log(`当前页: ${val}`);
    },
    // 列表选中时触发方法
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 搜索栏-搜索按钮
    searchBtn() {
      //console.log(this.form);
    },
    // 搜索栏-清空按钮
    offBtn() {
      this.reload();
    },
    // 列表-修改按钮
    handleUpdate(row) {
      this.$router.push({ name: 'Commodity pools', query: { flang:row.flang,id:row.id,contractNo:row.contractNo } });
    },
    // 列表-删除按钮
    handleDel(row) {
      this.$confirm('Whether or not to delete?', 'warning', {
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        type: 'warning'
      })
        .then(() => {})
        .catch(function() {});
    },
    // 弹窗-新增确定
    createData() {},
    // 弹窗-修改确定
    updateData() {}
  }
};
</script>
<style lang="less" scoped>
.demoWrapper {
  .el-input {
    width: 200px;
    margin-right: 30px;
  }
  ::v-deep .el-input__inner {
    height: 32px;
    line-height: 30px;
  }
  ::v-deep .el-pagination__editor.el-input {
    width: 50px;
  }
  .pageWrapper {
    padding: 10px;
    // float: right;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.heard_box {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 10px;
  div {
    label {
      margin-right: 5px;
    }
  }
}
.logo_no {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.serch_box {
  width: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 16px solid #eff0f4;
  border-radius: 8px 8px 0 0;
}
.fixed_form {
  display: flex;
  flex-wrap: wrap;
  div {
    margin-left: 25px;
    display: flex !important;
    flex-direction: column !important;
    /deep/.el-form-item__label {
      text-align: left !important;
    }
  }
  div:nth-child(6) {
    div {
      flex-direction: row !important;
    }
  }
  div:nth-child(7) {
    div {
      flex-direction: row !important;
    }
  }
}
.scrollbar {
  padding: 20px;
  box-sizing: border-box;
}
/deep/.el-drawer__header {
  font-weight: bold;
  font-size: 20px;
}
.dialog-footer {
  position: absolute;
  bottom: 5vh;
  right: 30%;
}
.box_p {
  width: 92%;
  margin: 0 auto;
  background-color: #fdf6ec;
  color: #e6a23c;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}
</style>
